import React from 'react';
import NewsSection from '../components/News/NewsSection';
import LinkedIn from '../components/News/Linkedin';

function News() {
  return (
    <>
      <NewsSection />
      {/* <LinkedIn /> */}
    </>
  );
}

export default News;