import React from 'react';
import { Link } from 'react-router-dom';
import Title from '../Title';
import Button from '../Button';
import AboutCompany from '../../assets/about/homepage-about-company.jpg';

import '../../index.css';

const About = () => {
  return (
    <div className='flex flex-col items-center p-6 md:p-12 gap-6 lg:gap-12'>
      <Title>
        <p className='flex flex-col justify-center items-center'>
          <span className=''>The Agility of a Fintech </span>
          <span>The Resilience of the Euroclear Infrastructure</span>
        </p>
      </Title>
      <div className='flex flex-col lg:flex-row mx-auto gap-12 max-w-[1600px] w-full'>
        <div className='flex flex-col lg:flex-row w-full gap-6 lg:gap-12'>
          <div className='lg:w-1/2 lg:order-2'>
            <img
              src={AboutCompany}
              alt='AboutPic'
              className='max-w-full max-h-full rounded object-contain'
            />
          </div>
          <div className='flex flex-col w-full lg:w-1/2 gap-6'>
            <div className='flex flex-col gap-6 text-lg lg:text-xl text-justify font-light text-primary-blue'>
              <p>
                In a world where individual preferences are paramount,
                personalised investment products are essential for delivering
                true customer value. Customisation enhances customer
                satisfaction, strengthens loyalty, and drives sustainable,
                long-term revenue growth.
              </p>
              <p>
                Achieving mass customisation or offering granular savings and
                investment products requires advanced technological
                capabilities.
              </p>
              <p>
                Quantessence is the leading technology partner enabling a wide
                range of granular and personalised savings, investment, and
                income products.
              </p>
              <div className='flex justify-center lg:justify-start'>
                <Link to='/about'>
                  <Button className='w-48'>Learn more</Button>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;